// This is a workaround to detect if the 3rd party cookie bar is shown or not.

import $ from 'jquery';

const DetectCookieBar = setInterval(function() {

  const oneTrustBanner = document.querySelector('#onetrust-banner-sdk');
  const cookieBarShown = oneTrustBanner ? true : false;

  if (cookieBarShown) {

    $('body').addClass('cookie-bar-shown');
    $('body').removeClass('cookie-bar-hidden');

    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {

        const display = ($('#onetrust-banner-sdk').css('display'));

        if (display === "none") {
          $('body').addClass('cookie-bar-hidden');
          $('body').removeClass('cookie-bar-shown');
        }
      });
    });

    var target = document.getElementById('onetrust-banner-sdk');
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    clearInterval(DetectCookieBar);
  }
  else {
    $('body').addClass('cookie-bar-hidden');
    $('body').removeClass('cookie-bar-shown');
  }

}, 100);

setTimeout(function () {
  clearInterval(DetectCookieBar);
},10000)