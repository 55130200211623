exports.components = {
  "component---src-pages-404-page-js": () => import("./../../../src/pages/404-page.js" /* webpackChunkName: "component---src-pages-404-page-js" */),
  "component---src-pages-after-an-hiv-test-js": () => import("./../../../src/pages/after-an-hiv-test.js" /* webpackChunkName: "component---src-pages-after-an-hiv-test-js" */),
  "component---src-pages-esp-js": () => import("./../../../src/pages/esp.js" /* webpackChunkName: "component---src-pages-esp-js" */),
  "component---src-pages-hiv-testing-guidelines-js": () => import("./../../../src/pages/hiv-testing-guidelines.js" /* webpackChunkName: "component---src-pages-hiv-testing-guidelines-js" */),
  "component---src-pages-how-to-get-hiv-tested-js": () => import("./../../../src/pages/how-to-get-hiv-tested.js" /* webpackChunkName: "component---src-pages-how-to-get-hiv-tested-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

